<template>
    <div class="w100 please_wait_div">
        <div>
            <img src="/images/hourglass-solid.svg" style="height: 100px;" />
        </div><br/>
        <p>
            {{ $t('Checkout.PLEASE_WAIT') }}
        </p>
    </div>
</template>

<script>
export default{
    data(){
        return{
            id: ''
        }
    },
    mounted(){
        this.$store.commit('SET_SANDWICH_SUCCESSDATA', {
            order_date_time: this.$route.query.order_date_time,
            email_id: this.$route.query.email_id,
            deep_link: this.$route.query.deep_link
        });
        setTimeout(() => {
            window.location = this.$route.query.payment_url
        }, 2000 );
    }
}
</script>